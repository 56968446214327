<template >
  <v-dialog
    v-model="show"
    width="auto"
    max-width="754"
    content-class="members-filter-dialog"
  >
    <v-card>
      <v-app-bar
        class="elevation-0 mb-8"
        color="transparent"
        height="auto"
        width="100%"
      >
        <v-toolbar-title>{{ $t("filters") }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon rounded outlined width="32" height="32">
          <span class="icomoon icon-close"></span>
        </v-btn>
      </v-app-bar>

      <v-card-title class="mb-3">
        <span class="section-title mr-3">{{ $t("questionnaires") }}</span>
        <span class="title-last-report">{{ $t("filter_on_last_report") }}</span>
      </v-card-title>

      <v-card-text>
        <v-container fluid>
          <v-row v-for="filter in questionnaireFilters" :key="filter.title">
            <v-col cols="7" class="p-0">
              <RangeProgressbar
                :title="filter.title"
                :maxValue="filter.maxValue"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-container fluid>
        <v-row>
          <v-card-title class="mb-3 mt-8">
            <span class="section-title mr-3">{{ $t("treatment") }}</span>
          </v-card-title>
        </v-row>

        <v-row class="mt-3 pl-4 pr-4">
          <v-col class="selector-container pt-4 pb-0" cols="12" md="4">
            <v-select
              hide-details
              label="Relapses"
              name="cars"
              id="cars"
              :items="['All options']"
              value="All options"
              append-icon="icomoon  icon-arrow-down"
            >
            </v-select>
          </v-col>
          <v-col class="selector-container pt-4 pb-0" cols="12" md="4">
            <v-select
              hide-details
              label="Feber"
              name="cars"
              id="cars"
              :items="['All options']"
              value="All options"
              append-icon="icomoon  icon-arrow-down"
            >
            </v-select>
          </v-col>
          <v-col class="selector-container pt-4 pb-0" cols="12" md="4">
            <v-select
              hide-details
              label="Relapses"
              name="cars"
              id="cars"
              :items="['All options']"
              value="All options"
              append-icon="icomoon  icon-arrow-down"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-container>

      <v-container fluid>
        <v-row>
          <v-card-title class="mb-3 mt-8">
            <span class="section-title mr-3">{{ $t("member") }}</span>
          </v-card-title>
        </v-row>

        <v-row class="mt-3 pl-5 pr-5">
          <v-col class="selector-container pt-4 pb-0" cols="12" md="6">
            <v-select
              hide-details
              label="Gender"
              name="cars"
              id="cars"
              :items="['All']"
              value="All"
              append-icon="icomoon  icon-arrow-down"
            >
            </v-select>
          </v-col>
          <v-col class="selector-container pt-4 pb-0" cols="12" md="6">
            <v-select
              hide-details
              label="condition"
              name="cars"
              id="cars"
              :items="['All']"
              value="All"
              append-icon="icomoon  icon-arrow-down"
            >
            </v-select>
          </v-col>
        </v-row>
      </v-container>

      <v-divider />

      <div class="actions p-0 justify-space-between d-flex align-center">
        <v-btn color="#333333" text @click="dialog = false">
          remove filters
        </v-btn>

        <v-spacer></v-spacer>

        <v-btn
          class="results-btn"
          :color="isLifeChamps ? '#fff' : '#004447'"
          text
          @click="dialog = false"
        >
          see results
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import RangeProgressbar from "@/components/RangeProgressbar.vue";
export default {
  name: "MembersListFilterDialog",

  data() {
    return {
      questionnaireFilters: [
        { title: "ASAS-HI score", maxValue: 17 },
        { title: "BASDAI score", maxValue: 10 },
        { title: "DAS-28 Painful joints", maxValue: 60 },
        { title: "DAS-28 Painful joints", maxValue: 60 },
        { title: "DAS-28 Imflamated joints", maxValue: 60 },
        { title: "HAQ score", maxValue: 3 },
      ],
    };
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    isLifeChamps: function () {
      return (
        this.$store.getters["auth/user"]?.theme_config.key == "lifechamps"
      );
    },
  },
  components: {
    RangeProgressbar,
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/dialogs/members-list-filter-dialog.scss">
</style>