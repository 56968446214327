<template>
  <v-container fluid>
    <div class="range-progressbar">
      <v-row align="center">
        <v-col cols="12" md="5" class="pa-2">
          <p class="title mb-0">{{ title }}</p>
        </v-col>

        <v-col cols="12" md="7" class="pt-8 pb-7 pl-0 pr-0">
          <v-range-slider
            :color="this.$store.getters['auth/user'].theme_config.primary_color"
            track-color="#DBDCDD"
            :track-fill-color="
              this.$store.getters['auth/user'].theme_config.primary_color
            "
            hide-details
            inverse-label
            height="8"
            :value="[0, 1]"
            :tick-labels="ranges"
            ticks="always"
            tick-size="4"
            min="0"
            :max="maxValue"
          >
            <template v-slot:thumb-label="props">
              {{ season(props.value) }}
            </template>
          </v-range-slider>
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
export default {
  name: "RangeProgressbar",

  props: {
    title: {
      type: String,
      default: "",
    },
    maxValue: {
      type: String,
      default: "0",
    },
  },

  computed: {
    ranges: function () {
      let max = parseInt(this.maxValue);
      let result = [];
      for (let i = 0; i <= max; i++) {
        if (i == 0 || i == Math.floor(max / 2) || i == max) {
          result.push(i.toString());
        } else {
          result.push("");
        }
      }

      return result;
    },
  },

  methods: {
    season(val) {
      return val;
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/range-progressbar.scss">
</style>